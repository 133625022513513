@font-face {
	font-family: "Manrope Fallback";
	src: local(Arial);
	size-adjust: 95%;
	ascent-override: normal;
	descent-override: normal;
	line-gap-override: 10%;
}

@font-face {
	font-family: "Inter Fallback";
	src: local(Arial);
	size-adjust: 99%;
	ascent-override: normal;
	descent-override: normal;
	line-gap-override: 12%;
}

@font-face {
	font-family: "Cormorant Garamond Fallback";
	src: local(Times New Roman);
	size-adjust: 86%;
	ascent-override: normal;
	descent-override: normal;
	line-gap-override: 30%;
}

#___griddo {
	font-family: var(--font-1);
}

.container {
	margin-inline: auto;
	width: 100%;
	max-width: var(--xs-max-width);
	padding-inline: var(--spc-2xs);

	@media (--sm) {
		max-width: var(--sm-max-width);
		padding-inline: var(--spc-lg);
	}

	@media (--md) {
		max-width: var(--md-max-width);
		padding-inline: var(--spc-3xl);
	}

	@media (--lg) {
		max-width: var(--lg-max-width);
		padding-inline: var(--spc-xl);
	}
}
