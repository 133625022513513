/*
  -----------------------------------
  🚫 DO NOT EDIT THIS FILE DIRECTLY 🚫
  -----------------------------------
*/

@custom-media --sm (min-width: 700px);
@custom-media --md (min-width: 1025px);
@custom-media --lg (min-width: 1681px);

#___griddo {
	/* animation */
	--duration-xs: 0.1s;
	--duration-sm: 0.2s;
	--duration-md: 0.3s;
	--duration-lg: 0.5s;
	--duration-xl: 0.75s;
	--easing-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
	--easing-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
	--easing-quart: cubic-bezier(0.77, 0, 0.175, 1);
	--easing-quint: cubic-bezier(0.86, 0, 0.07, 1);
	--easing-expo: cubic-bezier(1, 0, 0, 1);
	/* borders */
	--border-width-xs: 16px;
	--border-width-sm: 24px;
	--border-width-md: 32px;
	--border-width-lg: 48px;
	/* containers */
	--xs-max-width: 100%;
	--sm-max-width: 100%;
	--md-max-width: 100%;
	--lg-max-width: 1920px;
	/* fontFamily */
	--font-1: Manrope, "Manrope Fallback", sans-serif;
	--font-2: Inter, "Inter Fallback", sans-serif;
	--font-3: "Cormorant Garamond", "Cormorant Garamond Fallback", serif;
	/* opacity */
	--opacity-1: 0.08;
	--opacity-2: 0.3;
	--opacity-3: 0.4;
	--opacity-4: 0.56;
	--opacity-5: 0.64;
	--opacity-6: 0.72;
	/* radius */
	--radius-sm: 4px;
	--radius-md: 8px;
	--radius-lg: 16px;
	--radius-full: 9999px;
	/* spacing */
	--spc-4xs: 4px;
	--spc-3xs: 8px;
	--spc-2xs: 16px;
	--spc-xs: 24px;
	--spc-sm: 32px;
	--spc-md: 40px;
	--spc-lg: 48px;
	--spc-xl: 56px;
	--spc-2xl: 64px;
	--spc-3xl: 80px;
	--spc-4xl: 96px;
	--spc-5xl: 128px;
	--size-2xs: 8px;
	--size-xs: 16px;
	--size-sm: 24px;
	--size-md: 32px;
	--size-lg: 48px;
	--size-xl: 56px;
	--size-2xl: 64px;
}
