.display-1 {
	font-family: var(--font-1);
	font-size: 60px;
	font-weight: 700;
	line-height: 64px;
	letter-spacing: 4px;
	@media (--sm) {
		font-size: 80px;
		line-height: 92px;
	}
	@media (--md) {
		font-size: 94px;
		line-height: 104px;
	}
	@media (--lg) {
		font-size: 100px;
		line-height: 136px;
	}
}

.display-xl {
	font-family: var(--font-1);
	font-size: 80px;
	font-weight: 800;
	line-height: normal;
	@media (--sm) {
		font-size: 120px;
	}
	@media (--md) {
		font-size: 160px;
	}
	@media (--lg) {
		font-size: 180px;
	}
}

.highlight-xl {
	font-family: var(--font-3);
	font-size: 80px;
	font-style: italic;
	font-weight: 300;
	line-height: normal;
	@media (--sm) {
		font-size: 94px;
	}
	@media (--md) {
		font-size: 150px;
	}
	@media (--lg) {
		font-size: 180px;
	}
}

.highlight-l {
	font-family: var(--font-3);
	font-size: 78px;
	font-style: italic;
	font-weight: 300;
	line-height: normal;
	@media (--sm) {
		font-size: 88px;
	}
	@media (--md) {
		font-size: 100px;
	}
	@media (--lg) {
		font-size: 126px;
	}
}

.highlight-m {
	font-family: var(--font-3);
	font-size: 48px;
	font-style: italic;
	font-weight: 300;
	line-height: normal;
	@media (--sm) {
		font-size: 60px;
		font-weight: 400;
	}
	@media (--lg) {
		font-size: 80px;
	}
}

.highlight-s {
	font-family: var(--font-3);
	font-size: 44px;
	font-style: italic;
	font-weight: 400;
	line-height: normal;
	@media (--sm) {
		font-size: 56px;
	}
	@media (--lg) {
		font-size: 64px;
	}
}

.highlight-xs {
	font-family: var(--font-3);
	font-size: 38px;
	font-style: italic;
	font-weight: 400;
	line-height: normal;
	@media (--sm) {
		font-size: 48px;
	}
}

.highlight-xxs {
	font-family: var(--font-3);
	font-size: 24px;
	font-style: italic;
	font-weight: 400;
	line-height: normal;
	@media (--sm) {
		font-size: 32px;
	}
}

.heading-xl {
	font-family: var(--font-1);
	font-size: 40px;
	font-weight: 700;
	line-height: 48px;
	@media (--sm) {
		font-size: 56px;
		line-height: 64px;
	}
	@media (--md) {
		font-size: 72px;
		line-height: 78px;
	}
	@media (--lg) {
		font-size: 80px;
		line-height: 88px;
	}
}

.heading-lg {
	font-family: var(--font-1);
	font-size: 32px;
	font-weight: 700;
	line-height: 40px;
	@media (--sm) {
		font-size: 48px;
		line-height: 56px;
	}
	@media (--md) {
		font-size: 60px;
		line-height: 68px;
	}
	@media (--lg) {
		font-size: 64px;
		line-height: 72px;
	}
}

.heading-md {
	font-family: var(--font-1);
	font-size: 28px;
	font-weight: 700;
	line-height: 32px;
	@media (--sm) {
		font-size: 40px;
		line-height: 48px;
	}
}

.heading-sm {
	font-family: var(--font-1);
	font-size: 24px;
	font-weight: 700;
	line-height: 30px;
	@media (--sm) {
		font-size: 32px;
		line-height: 40px;
	}
}

.heading-sm-light {
	font-family: var(--font-1);
	font-size: 24px;
	font-weight: 300;
	line-height: 30px;
	@media (--sm) {
		font-size: 32px;
		line-height: 40px;
	}
}

.heading-xs {
	font-family: var(--font-1);
	font-size: 20px;
	font-weight: 700;
	line-height: 28px;
	letter-spacing: 2px;
	@media (--lg) {
		font-size: 24px;
		line-height: 30px;
	}
}

.heading-xs-medium {
	font-family: var(--font-1);
	font-size: 20px;
	font-weight: 500;
	line-height: 28px;
	@media (--lg) {
		font-size: 24px;
		line-height: 30px;
	}
}

.heading-xs-light {
	font-family: var(--font-1);
	font-size: 20px;
	font-weight: 300;
	line-height: 28px;
	@media (--lg) {
		font-size: 24px;
		line-height: 30px;
	}
}

.body-lead-lg {
	font-family: var(--font-2);
	font-size: 22px;
	font-weight: 300;
	line-height: 26px;
	& p {
		margin-bottom: var(--spc-xs);

		&:last-child {
			margin-bottom: 0;
		}
	}
	@media (--sm) {
		font-size: 24px;
		line-height: 32px;
	}
	@media (--md) {
		font-size: 28px;
		line-height: 36px;
	}
	@media (--lg) {
		font-size: 32px;
		line-height: 40px;
	}
}

.body-lead-md-medium {
	font-family: var(--font-2);
	font-size: 20px;
	font-weight: 500;
	line-height: 24px;
	& p {
		margin-bottom: var(--spc-xs);

		&:last-child {
			margin-bottom: 0;
		}
	}
	@media (--sm) {
		font-size: 22px;
		line-height: 28px;
	}
	@media (--md) {
		font-size: 24px;
	}
}

.body-lead-md-regular {
	font-family: var(--font-2);
	font-size: 20px;
	line-height: 24px;
	& p {
		margin-bottom: var(--spc-xs);

		&:last-child {
			margin-bottom: 0;
		}
	}
	@media (--sm) {
		font-size: 22px;
		line-height: 28px;
	}
	@media (--md) {
		font-size: 24px;
	}
}

.body-lead-md-light {
	font-family: var(--font-2);
	font-size: 20px;
	font-weight: 300;
	line-height: 24px;
	& p {
		margin-bottom: var(--spc-xs);

		&:last-child {
			margin-bottom: 0;
		}
	}
	@media (--sm) {
		font-size: 22px;
		line-height: 28px;
	}
	@media (--md) {
		font-size: 24px;
	}
}

.body-light {
	font-family: var(--font-2);
	font-size: 18px;
	font-weight: 300;
	line-height: 22px;
	& p {
		margin-bottom: var(--spc-2xs);

		&:last-child {
			margin-bottom: 0;
		}
	}
	@media (--sm) {
		font-size: 20px;
		line-height: 24px;
	}
}

.body-regular {
	font-family: var(--font-2);
	font-size: 18px;
	font-weight: 400;
	line-height: 22px;
	& p {
		margin-bottom: var(--spc-2xs);

		&:last-child {
			margin-bottom: 0;
		}
	}
	@media (--sm) {
		font-size: 20px;
		line-height: 24px;
	}
}

.body-inline-md {
	font-family: var(--font-2);
	font-size: 16px;
	line-height: 20px;
	& p {
		margin-bottom: var(--spc-2xs);

		&:last-child {
			margin-bottom: 0;
		}
	}
	@media (--sm) {
		font-size: 18px;
		line-height: 22px;
	}
}

.body-inline-sm {
	font-family: var(--font-2);
	font-size: 14px;
	line-height: 18px;
	& p {
		margin-bottom: var(--spc-3xs);

		&:last-child {
			margin-bottom: 0;
		}
	}
	@media (--sm) {
		font-size: 16px;
		line-height: 20px;
	}
}

.body-inline-xs {
	font-family: var(--font-2);
	font-size: 12px;
	line-height: 16px;
	& p {
		margin-bottom: var(--spc-3xs);

		&:last-child {
			margin-bottom: 0;
		}
	}
	@media (--sm) {
		font-size: 14px;
		line-height: 18px;
	}
}

.label-button {
	font-family: var(--font-1);
	font-size: 16px;
	line-height: 20px;
	text-transform: uppercase;
}

.label-button-text {
	font-family: var(--font-2);
	font-size: 16px;
	font-weight: 500;
	line-height: 22px;
}

.menu {
	font-family: var(--font-1);
	font-size: 18px;
	font-weight: 300;
	line-height: 22px;
	text-transform: uppercase;
	@media (--md) {
		font-size: 16px;
		line-height: 20px;
	}
}

.submenu {
	font-family: var(--font-1);
	font-size: 14px;
	font-weight: 300;
	line-height: 20px;
	text-transform: uppercase;
}

.tags {
	font-family: var(--font-1);
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: 4px;
	text-transform: uppercase;
	@media (--sm) {
		font-size: 18px;
	}
}

.quote {
	font-family: var(--font-1);
	font-size: 38px;
	font-weight: 500;
	line-height: 46px;
	letter-spacing: 0.5px;
	@media (--sm) {
		font-size: 48px;
		line-height: 56px;
	}
	@media (--md) {
		font-size: 56px;
		line-height: 64px;
	}
}

.breadcrumb-active {
	font-family: var(--font-2);
	font-size: 12px;
	font-weight: 500;
	line-height: 16px;
	letter-spacing: 0.5px;
	@media (--md) {
		font-size: 16px;
		line-height: 20px;
	}
}

.breadcrumb {
	font-family: var(--font-2);
	font-size: 12px;
	font-weight: 300;
	line-height: 16px;
	letter-spacing: 0.5px;
	@media (--md) {
		font-size: 16px;
		line-height: 20px;
	}
}

.inline-link {
	font-family: var(--font-2);
	font-size: 18px;
	line-height: 22px;
	letter-spacing: 0.5px;
	text-decoration: underline;
	@media (--sm) {
		font-size: 20px;
		line-height: 24px;
	}
}
