/*
  -----------------------------------
  🚫 DO NOT EDIT THIS FILE DIRECTLY 🚫
  -----------------------------------
*/

[data-theme="ecija"] {
	/* brandColors */
	--brand-1: #c9251c;
	--brand-2: #ffffff;
	--brand-3: #101010;
}
[data-theme="ecija"][data-subtheme="default"],
[data-theme="ecija"] [data-subtheme="default"] {
	/* buttons */
	--button-1-bg: #c9251c;
	--button-1-bg-hover: #b21e16;
	--button-1-bg-active: #b21e16;
	--button-1-bg-focus: #c9251c;
	--button-1-bg-disabled: #b3b3b3;
	--button-1-border: transparent;
	--button-1-border-hover: transparent;
	--button-1-border-active: transparent;
	--button-1-border-focus: #8d1a14;
	--button-1-border-disabled: transparent;
	--button-1-text: #ffffff;
	--button-1-text-hover: #ffffff;
	--button-1-text-active: #ffffff;
	--button-1-text-focus: #ffffff;
	--button-1-text-disabled: #7c7c7c;
	--button-1-focus: #8d1a14;
	--button-1-focus-width: 2px;
	--button-1-focus-style: solid;
	--button-2-bg: #101010;
	--button-2-bg-hover: #313131;
	--button-2-bg-active: #313131;
	--button-2-bg-focus: #101010;
	--button-2-bg-disabled: #b3b3b3;
	--button-2-border: transparent;
	--button-2-border-hover: transparent;
	--button-2-border-active: transparent;
	--button-2-border-focus: #8d1a14;
	--button-2-border-disabled: transparent;
	--button-2-text: #ffffff;
	--button-2-text-hover: #ffffff;
	--button-2-text-active: #ffffff;
	--button-2-text-focus: #ffffff;
	--button-2-text-disabled: #7c7c7c;
	--button-2-focus: #8d1a14;
	--button-2-focus-width: 2px;
	--button-2-focus-style: solid;
	--button-3-bg: transparent;
	--button-3-bg-hover: #e8e8e8;
	--button-3-bg-active: #e8e8e8;
	--button-3-bg-focus: transparent;
	--button-3-bg-disabled: #b3b3b3;
	--button-3-border: #101010;
	--button-3-border-hover: #101010;
	--button-3-border-active: #101010;
	--button-3-border-focus: #8d1a14;
	--button-3-border-disabled: #b3b3b3;
	--button-3-text: #101010;
	--button-3-text-hover: #101010;
	--button-3-text-active: #101010;
	--button-3-text-focus: #101010;
	--button-3-text-disabled: #7c7c7c;
	--button-3-focus: #8d1a14;
	--button-3-focus-width: 2px;
	--button-3-focus-style: solid;
	--button-text-bg-hover: #b21e16;
	--button-text-text: #c9251c;
	--button-text-text-hover: #b21e16;
	--button-text-text-active: #b21e16;
	--button-text-text-focus: #c9251c;
	--button-text-text-disabled: #7c7c7c;
	--button-text-focus: #8d1a14;
	--button-text-focus-width: 1px;
	--button-text-focus-style: solid;
	/* links */
	--link-text: #c9251c;
	--link-text-hover: #b21e16;
	--link-text-active: #b21e16;
	--link-text-disabled: #8c8c8c;
	--link-text-focus: #c9251c;
	--link-text-focus-width: 1px;
	--link-text-focus-style: solid;
	--link-menu: #101010;
	--link-menu-hover: #c9251c;
	--link-menu-active: #c9251c;
	--link-menu-disabled: #8c8c8c;
	--link-menu-focus: #101010;
	--link-menu-focus-width: 1px;
	--link-menu-focus-style: solid;
	/* colors */
	--overlay-1: #00000066;
	--overlay-1-noalpha: #000000;
	--overlay-2: #ffffffcc;
	--overlay-2-noalpha: #ffffff;
	--text-1: #101010;
	--text-2: #c9251c;
	--text-3: #575757;
	--text-inverse: #ffffff;
	--icon-1: #000000;
	--icon-2: #c9251c;
	--icon-3: #8c8c8c;
	--icon-inverse: #ffffff;
	--bg-ui: #ffffff;
	--bg-1: #ffffff;
	--bg-2: #f6f6f6;
	--bg-3: #c9251c;
	--bg-disabled: #b3b3b3;
	--border-1: #101010;
	--border-2: #c9251c;
	--border-3: #e2e2e2;
	--success: #00610f;
	--error: #e33e35;
	--focus-1: #8d1a14;
	--negative-1: #101010;
	--negative-2: #ffffff;
	--graphics-4: #ffc7de;
	--graphics-8: rgba(201, 37, 28, 0.3);
}
[data-theme="ecija"][data-subtheme="default-alt"],
[data-theme="ecija"] [data-subtheme="default-alt"] {
	/* buttons */
	--button-1-bg: #c9251c;
	--button-1-bg-hover: #b21e16;
	--button-1-bg-active: #b21e16;
	--button-1-bg-focus: #c9251c;
	--button-1-bg-disabled: #b3b3b3;
	--button-1-border: transparent;
	--button-1-border-hover: transparent;
	--button-1-border-active: transparent;
	--button-1-border-focus: #8d1a14;
	--button-1-border-disabled: transparent;
	--button-1-text: #ffffff;
	--button-1-text-hover: #ffffff;
	--button-1-text-active: #ffffff;
	--button-1-text-focus: #ffffff;
	--button-1-text-disabled: #7c7c7c;
	--button-1-focus: #8d1a14;
	--button-1-focus-width: 2px;
	--button-1-focus-style: solid;
	--button-2-bg: #101010;
	--button-2-bg-hover: #313131;
	--button-2-bg-active: #313131;
	--button-2-bg-focus: #101010;
	--button-2-bg-disabled: #b3b3b3;
	--button-2-border: transparent;
	--button-2-border-hover: transparent;
	--button-2-border-active: transparent;
	--button-2-border-focus: #8d1a14;
	--button-2-border-disabled: transparent;
	--button-2-text: #ffffff;
	--button-2-text-hover: #ffffff;
	--button-2-text-active: #ffffff;
	--button-2-text-focus: #ffffff;
	--button-2-text-disabled: #7c7c7c;
	--button-2-focus: #8d1a14;
	--button-2-focus-width: 2px;
	--button-2-focus-style: solid;
	--button-3-bg: transparent;
	--button-3-bg-hover: #e8e8e8;
	--button-3-bg-active: #e8e8e8;
	--button-3-bg-focus: transparent;
	--button-3-bg-disabled: #b3b3b3;
	--button-3-border: #101010;
	--button-3-border-hover: #101010;
	--button-3-border-active: #101010;
	--button-3-border-focus: #8d1a14;
	--button-3-border-disabled: #b3b3b3;
	--button-3-text: #101010;
	--button-3-text-hover: #101010;
	--button-3-text-active: #101010;
	--button-3-text-focus: #101010;
	--button-3-text-disabled: #7c7c7c;
	--button-3-focus: #8d1a14;
	--button-3-focus-width: 2px;
	--button-3-focus-style: solid;
	--button-text-bg-hover: #b21e16;
	--button-text-text: #c9251c;
	--button-text-text-hover: #b21e16;
	--button-text-text-active: #b21e16;
	--button-text-text-focus: #c9251c;
	--button-text-text-disabled: #7c7c7c;
	--button-text-focus: #8d1a14;
	--button-text-focus-width: 1px;
	--button-text-focus-style: solid;
	/* links */
	--link-text: #c9251c;
	--link-text-hover: #b21e16;
	--link-text-active: #b21e16;
	--link-text-disabled: #8c8c8c;
	--link-text-focus: #c9251c;
	--link-text-focus-width: 1px;
	--link-text-focus-style: solid;
	--link-menu: #101010;
	--link-menu-hover: #c9251c;
	--link-menu-active: #c9251c;
	--link-menu-disabled: #8c8c8c;
	--link-menu-focus: #101010;
	--link-menu-focus-width: 1px;
	--link-menu-focus-style: solid;
	/* colors */
	--overlay-1: #00000066;
	--overlay-1-noalpha: #000000;
	--overlay-2: #ffffffcc;
	--overlay-2-noalpha: #ffffff;
	--text-1: #101010;
	--text-2: #c9251c;
	--text-3: #575757;
	--text-inverse: #ffffff;
	--icon-1: #000000;
	--icon-2: #c9251c;
	--icon-3: #8c8c8c;
	--icon-inverse: #ffffff;
	--bg-ui: #ffffff;
	--bg-1: #f6f6f6;
	--bg-2: #ffffff;
	--bg-3: #c9251c;
	--bg-disabled: #b3b3b3;
	--border-1: #101010;
	--border-2: #c9251c;
	--border-3: #e2e2e2;
	--success: #00610f;
	--error: #e33e35;
	--focus-1: #8d1a14;
	--negative-1: #101010;
	--negative-2: #ffffff;
	--graphics-4: #ffc7de;
	--graphics-8: rgba(201, 37, 28, 0.3);
}
[data-theme="ecija"][data-subtheme="inverse"],
[data-theme="ecija"] [data-subtheme="inverse"] {
	/* buttons */
	--button-1-bg: #f1f1f1;
	--button-1-bg-hover: #ffffff;
	--button-1-bg-active: #ffffff;
	--button-1-bg-focus: #f1f1f1;
	--button-1-bg-disabled: #d5d5d5;
	--button-1-border: transparent;
	--button-1-border-hover: transparent;
	--button-1-border-active: transparent;
	--button-1-border-focus: #ffffff;
	--button-1-border-disabled: transparent;
	--button-1-text: #101010;
	--button-1-text-hover: #101010;
	--button-1-text-active: #101010;
	--button-1-text-focus: #101010;
	--button-1-text-disabled: #727272;
	--button-1-focus: #ffffff;
	--button-1-focus-width: 2px;
	--button-1-focus-style: solid;
	--button-2-bg: #ffffff;
	--button-2-bg-hover: #dddcdc;
	--button-2-bg-active: #dddcdc;
	--button-2-bg-focus: #ffffff;
	--button-2-bg-disabled: #d5d5d5;
	--button-2-border: transparent;
	--button-2-border-hover: transparent;
	--button-2-border-active: transparent;
	--button-2-border-focus: #ffffff;
	--button-2-border-disabled: transparent;
	--button-2-text: #101010;
	--button-2-text-hover: #101010;
	--button-2-text-active: #101010;
	--button-2-text-focus: #101010;
	--button-2-text-disabled: #727272;
	--button-2-focus: #ffffff;
	--button-2-focus-width: 2px;
	--button-2-focus-style: solid;
	--button-3-bg: transparent;
	--button-3-bg-hover: #ffffff;
	--button-3-bg-active: #ffffff;
	--button-3-bg-focus: transparent;
	--button-3-bg-disabled: #d5d5d5;
	--button-3-border: #ffffff;
	--button-3-border-hover: #ffffff;
	--button-3-border-active: #ffffff;
	--button-3-border-focus: #ffffff;
	--button-3-border-disabled: #b3b3b3;
	--button-3-text: #ffffff;
	--button-3-text-hover: #101010;
	--button-3-text-active: #101010;
	--button-3-text-focus: #101010;
	--button-3-text-disabled: #727272;
	--button-3-focus: #ffffff;
	--button-3-focus-width: 2px;
	--button-3-focus-style: solid;
	--button-text-bg-hover: #dddcdc;
	--button-text-text: #f1f1f1;
	--button-text-text-hover: #dddcdc;
	--button-text-text-active: #dddcdc;
	--button-text-text-focus: #f1f1f1;
	--button-text-text-disabled: #727272;
	--button-text-focus: #ffffff;
	--button-text-focus-width: 1px;
	--button-text-focus-style: solid;
	/* links */
	--link-text: #f1f1f1;
	--link-text-hover: #ffffff;
	--link-text-active: #ffffff;
	--link-text-disabled: #8c8c8c;
	--link-text-focus: #f1f1f1;
	--link-text-focus-width: 1px;
	--link-text-focus-style: solid;
	--link-menu: #ffffff;
	--link-menu-hover: #ffffff;
	--link-menu-active: #ffffff;
	--link-menu-disabled: #8c8c8c;
	--link-menu-focus: #ffffff;
	--link-menu-focus-width: 1px;
	--link-menu-focus-style: solid;
	/* colors */
	--overlay-1: #ffffff66;
	--overlay-1-noalpha: #ffffff;
	--overlay-2: #202020cc;
	--overlay-2-noalpha: #202020;
	--text-1: #ffffff;
	--text-2: #ffffff;
	--text-3: #cacaca;
	--text-inverse: #101010;
	--icon-1: #ffffff;
	--icon-2: #ffffff;
	--icon-3: #8c8c8c;
	--icon-inverse: #101010;
	--bg-ui: #454545;
	--bg-1: #454545;
	--bg-2: #101010;
	--bg-3: #ffffff;
	--bg-disabled: #b3b3b3;
	--border-1: #ffffff;
	--border-2: #ffffff;
	--border-3: #383838;
	--success: #00d422;
	--error: #ff5b52;
	--focus-1: #ffffff;
	--negative-1: #ffffff;
	--negative-2: #101010;
	--graphics-4: #ffc7de;
	--graphics-8: rgba(201, 37, 28, 0.3);
}
